<template>
  <!-- 需求发布列表详情-需求应答列表-应答详情 -->
  <div v-if="item && item.id" class="zt-page-content">
    <div style="height: calc(100% - 70px); overflow: auto">
      <div
        v-if="needItem && needItem.id"
        class="zt-block"
        style="min-width: 850px"
      >
        <div v-if="needItem && needItem.type != 'dayang'" class="item">
          <h3 class="font14-grey ellipsis">{{ needItem.title }}</h3>
          <p
            v-if="needItem[needItem.type] && needItem[needItem.type].cat"
            style="color: #b3b3b3; margin-bottom: 0"
          >
            {{ needItem[needItem.type].cat.name }}
          </p>
        </div>
        <div class="list-item flex">
          <div style="width: 300px">
            <div class="flex">
              <div style="width: 106px; height: 106px; margin-right: 10px">
                <div
                  v-if="item.comp && item.comp.logo"
                  class="bg_img"
                  :style="{
                    backgroundImage:
                      'url(' + imgUrl + item.comp.logo + '!width_250px' + ')',
                  }"
                ></div>
                <div
                  v-else
                  class="bg_img"
                  :style="{
                    backgroundImage:
                      'url(' +
                      staticUrl +
                      'design/placeholder/zhanweilogo%402x.jpg!width_250px' +
                      ')',
                  }"
                ></div>
              </div>
              <div
                v-if="item.comp && item.comp.name"
                style="text-align: left; position: relative"
                class="flex-1 flex-justify-between"
              >
                <h3 class="font14-grey ellipsis1">{{ item.comp.name }}</h3>
                <el-button
                  v-if="needItem.type != 'dayang'"
                  @click.stop="viewIntro(item.comp.id)"
                  style="color: #24B1EB; position: absolute; bottom: 0; left: 0"
                  type="text"
                  >查看公司详情资质 ></el-button
                >
              </div>
            </div>
          </div>
          <div class="list-item-tr">
            <div class="font14-grey1">价格</div>
            <div>￥{{ item[needItem.type].price | priceFilt }}</div>
          </div>
          <div v-if="needItem.type != 'dayang'" class="list-item-tr">
            <div class="font14-grey1">最早交货</div>
            <div>{{ item[needItem.type].giveDate | moment("YYYY-MM-DD") }}</div>
          </div>
          <div
            v-if="needItem.type != 'dayang' && needItem.type != 'gaikuang'"
            style="min-width: 120px; padding: 10px 30px"
            class="list-item-tr flex-1"
          >
            <el-button
              @click="viewProduct(item)"
              size="mini"
              type="primary"
              round
              >查看商品</el-button
            >
          </div>
          <div
            v-if="needItem.type"
            class="flex flex-align-center flex-pack-center"
            style="width: 180px"
          >
            <div style="color: #2bbaae">已应答</div>
          </div>
        </div>
      </div>
      <DayangDetail
        v-if="needItem.type == 'dayang'"
        :item="needItem"
        @onSuccess="refreshItems"
      ></DayangDetail>
      <div class="zt-block" style="min-width: 950px; min-height: 300px">
        <div class="reply-header">我要回复</div>
        <div class="reply-content">
          <!-- v-if="reply_list && reply_list.length>0" -->
          <div class="reply-input flex">
            <div>
              <i class="iconfont icon-touxiang"></i>
            </div>
            <div class="flex-1">
              <div>
                <el-input
                  style="width: 100%; height: 100%"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入回复内容"
                  v-model="replyText"
                  maxlength="150"
                  show-word-limit
                ></el-input>
              </div>
              <div style="text-align: right; padding-top: 5px">
                <el-button @click="replySubmit()" size="small" type="primary"
                  >回复</el-button
                >
              </div>
            </div>
          </div>
          <!-- v-if="reply_list && reply_list.length>0" -->
          <div class="reply-header1 flex">
            <div>历史回复</div>
            <span>共{{ (reply_list && reply_list.length) || 0 }}条回答</span>
          </div>
          <!-- v-if="reply_list && reply_list.length>0" -->
          <div class="list-reply">
            <div
              v-for="(item1, idx1) in reply_list"
              :key="idx1"
              class="reply-item"
            >
              <div class="reply-name flex">
                <!-- <div class="bg_img"
                :style="{backgroundImage: 'url(' + imgUrl+item1.fromComp.logo+'!width_250px' + ')'}"></div>-->
                <div v-if="item1.fromCompId == comp_id">我的回复</div>
                <div v-else>{{ item1.fromComp.name }}</div>
              </div>
              <div class="reply-padding">
                <div class="reply-text">{{ item1.content }}</div>
                <div class="reply-time flex">
                  <div class="flex-1">
                    {{ item1.createdAt | moment("YYYY年MM月DD日") }}
                  </div>
                  <!-- <el-button @click="popReply(item1,idx)" type="text">回复</el-button> -->
                  <!-- <el-button v-if="item1.fromCompId == comp_id" @click="edit_reply(item1,idx)" size="small" type="text">修改</el-button> -->
                  <el-button
                    v-if="item1.fromCompId == comp_id"
                    @click="del_reply(item1)"
                    style="color: #f66f6a"
                    size="small"
                    type="text"
                    >撤回</el-button
                  >
                </div>
              </div>
              <!-- <div v-if="replyIndex === idx" class="reply-input">
                            <div>
                                <el-input style="width:100%;height:100%" type="textarea" :rows="3" placeholder="请输入回复内容"
                                v-model="replyText"></el-input>
                            </div>
                            <div style="text-align: right;padding-top:5px;">
                                <span style="line-height: 32px;margin-right: 10px;" class="font14-grey">{{replyText.length}}</span>
                                <el-button v-if="replyType == 2" @click="replySubmit()" size="small" type="primary">保存</el-button>
                                <el-button v-else @click="replySubmit()" size="small" type="primary">发送</el-button>
                            </div>
              </div>-->
            </div>
          </div>
          <div
            style="padding: 30px; text-align: center"
            v-if="reply_list && reply_list.length == 0"
          >
            暂无回复内容
          </div>
        </div>
      </div>
    </div>
    <!-- 打样按钮是否需要放置在最底侧 -->
    <div class="creatOrder-button">
      <el-button
        v-if="item.orderId"
        @click="viewOrder"
        size="mini"
        type="primary"
        round
        >查看订单</el-button
      >
      <el-button v-else @click="goOrder" size="mini" type="primary" round
        >生成订单并签约</el-button
      >
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import {
  getRequireId,
  requireOfferId,
  replyList,
  addReply,
  editReply,
  delReply,
} from "@/service/want";
import DayangDetail from "./DayangDetail.vue";
export default {
  components: {
    DayangDetail,
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      staticUrl: STATIC_URL_PRE,
      item: {},
      needItem: {},
      reply_list: null,
      // replyFlag: false,
      replyItem: "",
      replyText: "",
      replyIndex: null,
      replyType: null,
      comp_id: "",
    };
  },
  created() {
    this.comp_id = this.$store.state.loginUser.comp.id;
    this.refreshItems();
  },
  methods: {
    viewOrder() {
      this.$router.push({
        path: "/factory/demandOrder",
        query: {
          type:
            this.needItem.type === "dayang"
              ? 0
              : this.needItem.type === "gaikuang"
              ? 1
              : null,
        },
      });
    },
    goOrder() {
      if (!this.item.contract) {
        this.$message.error("对方暂未上传合同,请耐心等待!");
        return false;
      }
      this.goto(
        "/want/wantFollow/creatOrder?id=" +
          this.$route.params.id +
          "&wantId=" +
          this.needItem.id
      );
    },
    goto(link) {
      this.$router.push(link);
    },
    back() {
      this.$router.go(-1);
    },
    viewIntro(id) {
      window.open("/zizhi/view/" + id);
    },
    viewProduct(item) {
      console.log(item);
      switch (this.needItem.type) {
        case "peitao":
          window.open(
            "/sale/mall/home/factory_detail/" + item[this.needItem.type].itemId
          );
          break;
        case "gaikuang":
          window.open(
            "/sale/mall/home/design_detail/" + item[this.needItem.type].itemId
          );
          break;
      }
    },
    refreshItems() {
      getRequireId(this.$route.params.wantId)
        .then((rst) => {
          this.needItem = rst;
        })
        .catch((err) => {
          console.error(err);
        });
      requireOfferId(this.$route.params.id)
        .then((rst) => {
          this.item = rst;
          this.replylist();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    replylist() {
      replyList(this.item.id)
        .then((rst) => {
          this.total = rst.count;
          this.reply_list = rst.rows;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    replySubmit() {
      if (this.replyText.trim()) {
        let item = {
          offerId: this.item.id,
          content: this.replyText,
        };
        if (this.replyType == 2) {
          // item.parentId = this.reply_list[this.reply_list.length-1].id
          editReply(this.replyItem.id, { content: this.replyText })
            .then((rst) => {
              this.replyFlag = false;
              this.replyItem = "";
              this.$message.success("保存成功");
              this.replylist();
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          addReply(item)
            .then((rst) => {
              this.replyFlag = false;
              this.replyText = "";
              this.$message.success("回复成功");
              this.replylist();
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },
    del_reply(item) {
      this.$confirm("确认是否撤回?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delReply(item.id)
          .then((rst) => {
            this.replyFlag = false;
            this.$message.success("撤回成功");
            this.replylist();
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.zt-block {
  box-shadow: none;
}
.item {
  padding: 20px;
  font-size: 14px;
  background: rgba(250, 250, 255, 1);
  border: 1px solid rgba(230, 230, 230, 1);
}
.item h3 {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
}
.list-item {
  padding: 20px;
  background: #fff;
}
.list-item:last-child {
  margin-bottom: 0;
}
.list-item > div {
  min-width: 180px;
  min-height: 100px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
}
.list-item > div:first-child {
  padding-left: 0;
  padding-right: 10px;
}
.list-item > div:last-child {
  border-right: none;
}
.list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.reply-header {
  padding: 0 30px;
  font-size: 14px;
  font-weight: bold;
  color: #292929;
  line-height: 52px;
  background: rgba(245, 245, 245, 1);
}
.reply-content {
  padding: 30px;
}
.reply-input {
  max-width: 500px;
  padding: 10px;
  /* border: 1px solid #ccc; */
  /* margin: 15px 0; */
}
.reply-input i {
  font-size: 48px;
  color: #d8d8d8;
  line-height: 54px;
  margin-right: 20px;
}
.reply-header1 {
  font-size: 14px;
  font-weight: bold;
  color: #292929;
  line-height: 40px;
  border-bottom: 1px solid #24B1EB;
}
.reply-header1 > span {
  font-weight: 400;
  font-size: 12px;
  color: #808080;
  margin-left: 10px;
}
.reply-item {
  padding: 20px 0 11px 0;
  border-bottom: 1px solid #dce1f1;
}
.reply-name {
  font-size: 14px;
  color: #24B1EB;
  line-height: 20px;
  margin-bottom: 14px;
}
.reply-name .bg_img {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  margin-right: 10px;
}
.reply-padding .reply-text {
  font-size: 14px;
  color: #292929;
  line-height: 20px;
  margin-bottom: 10px;
}
.reply-padding .reply-time {
  font-size: 12px;
  color: #b3b3b3;
  line-height: 20px;
}
.creatOrder-button {
  padding: 20px 30px;
  background: #fff;
  border: 1px solid rgba(230, 230, 230, 1);
}
</style>